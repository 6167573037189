import React from "react";

import Layout from "components/layout/Layout/Layout";
import PrivacyPolicyContent from "components/pages/privacy-policy/PrivacyPolicyContent/PrivacyPolicyContent";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const PrivacyPolicy = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("PrivacyPolicy")} />
      <Layout location={location}>
        <PrivacyPolicyContent />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
