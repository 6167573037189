import SeoData from "data/seo-data.json";
import Translate from "utils/translation/translation";

export const GetSeoData = (name: string) => {
  const data = JSON.parse(JSON.stringify(SeoData[name]));

  data.title = Translate(data.title);
  data.description = Translate(data.description);
  data.openGraph.title = Translate(data.openGraph.title);
  data.openGraph.description = Translate(data.openGraph.description);

  return data;
};
