import { CalculateFontSize, CalculateFromWidth } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const PrivacyPolicyTitleFont = css`
  ${({ theme: { fonts } }) => fonts.demiBold}
  ${CalculateFontSize("font-size", { mobile: 8, tablet: 7, desktop: 9 })}
  ${CalculateFontSize("line-height", { mobile: 9, tablet: 9, desktop: 9 })}
  ${CalculateFontSize("letter-spacing", { mobile: -0.5, tablet: -0.5, desktop: -0.5 })}
  ${CalculateFromWidth("word-spacing", { mobile: 2, tablet: 2, desktop: 2 })}
`;
export const PrivacyPolicySubtitleFont = css`
  ${({ theme: { fonts } }) => fonts.light}
  ${CalculateFontSize("font-size", { mobile: 2.5, tablet: 3, desktop: 4 })}
  ${CalculateFontSize("line-height", { mobile: 5, tablet: 5, desktop: 8 })}
`;
export const PrivacyPolicyPointFont = css`
  text-align: justify;
`;
