import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { PrivacyPolicyPointFont } from "styled/common/font-styles/PrivacyPolicyFonts";
import styled, { css } from "styled-components";

const PrivacyPolicySubpoint = css`
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 2, desktop: 2 })}
  ${CalculateFromWidth("margin-left", { mobile: 2.5, tablet: 2.5, desktop: 2.5 })}
`;

export const PrivacyPolicyPoint = styled.p<{ isSubpoint: boolean }>`
  ${PrivacyPolicyPointFont}

  ${CalculateFromHeight("margin-top", { mobile: 3, tablet: 3, desktop: 3 })}
  ${({ isSubpoint }) => isSubpoint && PrivacyPolicySubpoint}
`;
