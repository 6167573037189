import React, { Fragment, useRef } from "react";

import {
  PrivacyPolicyContentWrapper,
  PrivacyPolicyParagraph,
  PrivacyPolicyParagraphsWrapper,
  PrivacyPolicyPointsWrapper,
  PrivacyPolicySubtitle,
  PrivacyPolicyTitle,
  PrivacyPolicyWrapper
} from "components/pages/privacy-policy/PrivacyPolicyContent/PrivacyPolicyContent.styled";
import PrivacyPolicyPoints from "components/pages/privacy-policy/PrivacyPolicyPoints/PrivacyPolicyPoints";
import PrivacyPolicy from "data/privacy-policy.json";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const PrivacyPolicyContent = () => {
  const policyTitle = useRef<HTMLSpanElement>(null);
  const policySubtitle = useRef<HTMLSpanElement>(null);

  useFirstRenderEffect(() => {
    gsap.from(policyTitle.current, {
      opacity: 0,
      x: 50,
      delay: 0.25,
      duration: 0.5,
      ease: Circ.easeOut
    });
    gsap.from(policySubtitle.current, {
      opacity: 0,
      x: -50,
      delay: 0.5,
      duration: 0.5,
      ease: Circ.easeOut
    });

    const delayMultiplier = 0.25;

    PrivacyPolicy.paragraphs.forEach((paragraph, paragraphIndex) => {
      gsap.from("#paragraph" + paragraphIndex, {
        opacity: 0,
        y: 20,
        delay: 1 + delayMultiplier * paragraphIndex,
        duration: 0.5,
        ease: Circ.easeOut
      });
      paragraph.entries.forEach((point, pointIndex) => {
        gsap.from("#point" + paragraphIndex + "" + pointIndex, {
          opacity: 0,
          y: 10,
          delay: 1 + delayMultiplier * paragraphIndex * 1 + (delayMultiplier * pointIndex) / 2,
          duration: 0.25,
          ease: Circ.easeOut
        });
      });
    });
  });

  return (
    <PrivacyPolicyWrapper>
      <PrivacyPolicyContentWrapper>
        <h1>
          <PrivacyPolicyTitle ref={policyTitle}>
            {Translate(PrivacyPolicy.title)}
          </PrivacyPolicyTitle>
          <br />
          <PrivacyPolicySubtitle ref={policySubtitle}>
            {Translate(PrivacyPolicy.subtitle)}
          </PrivacyPolicySubtitle>
        </h1>
        <PrivacyPolicyParagraphsWrapper>
          {PrivacyPolicy.paragraphs.map((paragraph, paragraphIndex) => {
            return (
              <Fragment key={"paragraph" + paragraphIndex}>
                <PrivacyPolicyParagraph id={"paragraph" + paragraphIndex}>
                  {"§ " + (paragraphIndex + 1) + ".\t" + Translate(paragraph.title)}
                </PrivacyPolicyParagraph>
                <PrivacyPolicyPointsWrapper>
                  <PrivacyPolicyPoints paragraph={paragraph} paragraphIndex={paragraphIndex} />
                </PrivacyPolicyPointsWrapper>
                <br />
              </Fragment>
            );
          })}
        </PrivacyPolicyParagraphsWrapper>
      </PrivacyPolicyContentWrapper>
    </PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicyContent;
