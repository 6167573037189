import React from "react";

import { PrivacyPolicyPoint } from "components/pages/privacy-policy/PrivacyPolicyPoints/PrivacyPolicyPoints.styled";
import Translate from "utils/translation/translation";

interface RenderPointsProps {
  paragraph: {
    title: string;
    entries: string[];
  };
  paragraphIndex: number;
}

const RenderPoints = ({ paragraph, paragraphIndex }: RenderPointsProps) => {
  let subpointCount = 0;
  let pointCount = 0;

  return (
    <>
      {paragraph.entries.map((point, pointIndex) => {
        const isSubpoint = point.includes(".");

        if (isSubpoint) {
          subpointCount++;
        } else {
          subpointCount = 0;
          pointCount++;
        }

        return (
          <PrivacyPolicyPoint
            isSubpoint={isSubpoint}
            key={"point" + paragraphIndex + "" + pointIndex}
            id={"point" + paragraphIndex + "" + pointIndex}
          >
            {(isSubpoint ? pointCount + "." + subpointCount : pointCount) +
              ".\t" +
              Translate(point)}
          </PrivacyPolicyPoint>
        );
      })}
    </>
  );
};

export default RenderPoints;
