import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  PrivacyPolicySubtitleFont,
  PrivacyPolicyTitleFont
} from "styled/common/font-styles/PrivacyPolicyFonts";
import styled from "styled-components";

export const PrivacyPolicyWrapper = styled.div`
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  ${CalculateFromWidth("padding-left", { mobile: 9, tablet: 9, desktop: 9 })}
  ${CalculateFromHeight("margin-top", { mobile: 25, tablet: 25, desktop: 25 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 25, tablet: 25, desktop: 25 })}
`;
export const PrivacyPolicyContentWrapper = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;
export const PrivacyPolicyTitle = styled.span`
  ${PrivacyPolicyTitleFont}
  justify-content: center;
  width: 100%;
  margin-left: 0;
`;
export const PrivacyPolicySubtitle = styled.span`
  ${PrivacyPolicySubtitleFont}
  justify-content: center;
  width: 100%;
  margin-left: 0;

  ${CalculateFromHeight("margin-top", { mobile: 3, tablet: 3, desktop: 3 })}
`;
export const PrivacyPolicyParagraphsWrapper = styled.div`
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 10, desktop: 10 })}
`;
export const PrivacyPolicyParagraph = styled.h3`
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 8, desktop: 8 })}
`;
export const PrivacyPolicyPointsWrapper = styled.div`
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 10, desktop: 10 })}
`;
